import { tv, VariantProps } from "tailwind-variants";

import {
  alignVariants,
  responsiveVariants,
  whitespaceVariants,
} from "../constant";

export const textControlVariants = tv(
  {
    base: "font-display",
    variants: {
      size: {
        1: "text-lg md:text-2xl",
        2: "text-5xs md:text-sm",
        3: "text-5xs md:text-sm uppercase",
        4: "text-5xs md:text-sm font-default font-normal",
      },
      align: {
        ...alignVariants,
      },
      whitespace: {
        ...whitespaceVariants,
      },

    },
    defaultVariants: {
      size: 1,
      align: "left",
      whitespace: "normal",
    },
  },
  { responsiveVariants: [ ...responsiveVariants ],

  },
);

export type TextControlVariantsProps = VariantProps<typeof textControlVariants>;
const textControlStyles = (variants: TextControlVariantsProps) => textControlVariants(variants);

export default textControlStyles;
