import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import { Theme } from "apps/website/maps/Theme.map";

import SectionAlertPrompt from "../../section/SectionAlertPrompt/SectionAlertPrompt";
import { TAlertStyle } from "../../feature/AlertPrompt/AlertPrompt.map";
import { Size } from "../../base/Text/Text.map";

interface IStoryBlokAlertPromptFeature {
  title: string;
  style: TAlertStyle;
  text_size: Size;
  body: string;
  hide_image: boolean;
}

export interface IStoryBlokAlertPrompt {
  alert: [IStoryBlokAlertPromptFeature];
  theme: Theme;
}

export const StoryBlokAlertPrompt = ({ blok }: IStoryBlokComponent<IStoryBlokAlertPrompt>) => {
  const [ alertPrompt ] = blok.alert;

  return (
    <SectionAlertPrompt
      alert={{
        ...alertPrompt,
        textSize: alertPrompt.text_size,
        children: alertPrompt.body,
        hideImage: alertPrompt.hide_image,
      }}
      theme={blok.theme}
    />
  );
};
