import { FC, ReactNode } from "react";

import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
  Align,
  Display,
} from "apps/website/components/base/Text/Text.map";
import Spacer from "apps/website/components/layout/Spacer/Spacer";

import {
  AvailableTag,
  AvailableSize,
  freeFlowTitleSizeMap,
} from "./FreeFlowTitle.map";

export interface IFreeFlowTitle {
  tag: AvailableTag;
  size?: AvailableSize;
  align?: Align;
  display?: Display;
  children: ReactNode;
}

const FreeFlowTitle: FC<IFreeFlowTitle> = ({ tag, size, display, align = "default", children }) => {

  const fontSize = size ? legacySizeCollectionMap[size] : legacySizeCollectionMap[freeFlowTitleSizeMap[tag]];
  return (
    <div data-component={FreeFlowTitle.name}>
      <Text tag={tag} display={display || "title"} size={fontSize} align={align} className="first-letter:uppercase">{ children }</Text>
      <Spacer size="lg" />
    </div>
  );
};

export default FreeFlowTitle;
