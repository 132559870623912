import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import SectionImage from
  "apps/website/components/section/SectionImage/SectionImage";
import {
  Size,
} from "apps/website/components/section/SectionImage/SectionImage.map";
import {
  Size as SpacerSize,
} from "apps/website/components/layout/Spacer/Spacer.map";

interface IStoryBlokImageSize {
  defaultImageSize: Size,
  smallImageSize: Size,
  mediumImageSize: Size,
  largeImageSize: Size,
  extraLargeImageSize: Size,
  twoExtraLargeImageSize: Size,
  threeExtraLargeImageSize: Size,
}

export interface IStoryBlokSectionImage {
  theme: Theme
  image: IStoryBlokImage;
  desktop_image: IStoryBlokImage;
  size: IStoryBlokImageSize;
  section_size: SpacerSize;
}

export const StoryBlokSectionImage = ({ blok }: IStoryBlokComponent<IStoryBlokSectionImage>) => (
  <>
    <SectionImage
      image={getStoryBlokImage(blok.image, true, ImageMax.CONTAINER_2XL)}
      desktopImage={getStoryBlokImage(blok.desktop_image, true, ImageMax.CONTAINER_2XL)}
      alt={blok.image.alt}
      theme={blok.theme}
      size={{
        default: blok.size.defaultImageSize,
        sm: blok.size.smallImageSize,
        md: blok.size.mediumImageSize,
        lg: blok.size.largeImageSize,
        xl: blok.size.extraLargeImageSize,
        "2xl": blok.size.twoExtraLargeImageSize,
        "3xl": blok.size.threeExtraLargeImageSize,
      }}
      sectionSize={blok.section_size}
    />
  </>
);
