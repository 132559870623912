import { FC } from "react";

import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Section from "apps/website/components/layout/Section/Section";
import { Theme } from "apps/website/maps/Theme.map";
import Container from "apps/website/components/layout/Container/Container";
import Column from "apps/website/components/layout/Column/Column";
import Grid from "apps/website/components/layout/Grid/Grid";
import { makeBreakpointClasses } from "apps/website/utils/classes";
import {
  Size as SpacerSize,
} from "apps/website/components/layout/Spacer/Spacer.map";

import {
  BreakpointSize,
  collectiveSizeClassMap,
  defaultSizeClassMap,
  Size,
} from "./SectionImage.map";

export interface ISectionImage {
  image: IImageProps;
  desktopImage: IImageProps;
  alt: string;
  size?: Size | BreakpointSize;
  theme?: Theme;
  className?: string;
  sectionSize?: SpacerSize;
}

const SectionImage: FC<ISectionImage> = ({ image, desktopImage, alt, size = "full", theme, className, sectionSize }) => {
  const computedClasses = makeBreakpointClasses([
    [ size, defaultSizeClassMap, collectiveSizeClassMap ],
  ], className);

  return (
    <Section component={SectionImage.name} theme={theme} size={sectionSize}>
      <Container>
        <Grid>
          <Column align="center" justify="center">
            <div className={`${computedClasses}`}>
              <Image
                image={image}
                advancedImages={
                  desktopImage ? {
                    mobile: image,
                    desktop: desktopImage,
                    extraLarge: desktopImage,
                  } : undefined}
                alt={alt}
              />
            </div>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionImage;
