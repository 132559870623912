import type {
  IStoryBlokAccordion,
} from "../../components/storyblok/sections/StoryBlokImageAccordionList";
import type { IAccordion } from "../../components/feature/Accordion/Accordion";

import { IRichTextOptions, renderStoryBlokRichText } from "./text";

export const getStoryBlokAccordion = (
  accordion: IStoryBlokAccordion,
  richtextOptions?: IRichTextOptions,
): IAccordion => {
  const AccordionContent = () => renderStoryBlokRichText(accordion.content, richtextOptions);
  return {
    title: accordion.title,
    Content: <AccordionContent />,
    theme: accordion.theme,
    type: accordion.design || "default",
  };
};

export const getStoryBlokAccordions = (
  accordions: IStoryBlokAccordion[],
  richtextOptions?: IRichTextOptions,
): IAccordion[] => (
  accordions.map((accordion) => getStoryBlokAccordion(accordion, richtextOptions))
);
