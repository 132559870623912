import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";

import TimeToComplete, {
  ITimeToCompleteProps,
} from "../../feature/TimeToComplete/TimeToComplete";
import { getSectionSize } from "../../layout/Section/Section.utils";
import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";
import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";

export interface ISectionTimeToCompleteProps {
  theme: Theme;
  timeToComplete: ITimeToCompleteProps;
}

const SectionTimeToComplete: FC<ISectionTimeToCompleteProps> = ({ theme, timeToComplete }) => (
  <Section component={ SectionTimeToComplete.name } theme={ theme } size={ getSectionSize(theme) }>
    <Container>
      <Grid>
        <Column justify="center" align="center">
          <TimeToComplete {...timeToComplete} />
        </Column>
      </Grid>
    </Container>
  </Section>
);

export default SectionTimeToComplete;
