import { FC, PropsWithChildren } from "react";

import {
  replaceWordsWithSticker,
  makeTitleFromArray,
} from "apps/website/utils/content/text";
import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import { Justify } from "apps/website/maps/Flex.map";
import Container from "apps/website/components/layout/Container/Container";
import Section from "apps/website/components/layout/Section/Section";
import Text from "apps/website/components/base/Text/Text";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Button from "apps/website/components/base/Button/Button";
import Image, {
  IAdvancedImages,
  IImageProps,
} from "apps/website/components/base/Image/Image";
import { ILink } from "apps/website/components/base/Button/Button.map";
import { ITitle } from "apps/website/components/page/component.types";
import Video, { IVideoPath } from "apps/website/components/base/Video/Video";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";

import {
  borderlessSectionBackgroundClassMap,
} from "../SectionIconTextList/Section.map";

import {
  Size,
  sizeClassMap,
  textGroupSizeMap,
  ISplashSubtitle,
  SplashLayout,
  splashLayoutMap,
} from "./SectionSplash.map";

export interface ISplashImage {
  image: IImageProps;
  contain: boolean;
}

export type TSectionSplashStyle = "default" | "quote";
export interface ISectionSplash {
  title: ITitle;
  subtitle?: ISplashSubtitle;
  body?: string;
  ctas?: ILink[];
  theme?: Theme;
  backgroundTheme?: Theme;
  hasBorders?: boolean;
  image?: IImageProps;
  advancedImages?: IAdvancedImages;
  disableImageLazyLoading?: boolean;
  video?: IVideoPath;
  videoPoster?: string;
  containImage?: boolean;
  component?: string;
  justify?: Justify;
  layout?: SplashLayout;
  size?: Size;
  style?: TSectionSplashStyle;
}

const SectionSplash: FC<PropsWithChildren<ISectionSplash>> = ({
  title,
  subtitle,
  body,
  ctas,
  image,
  advancedImages,
  disableImageLazyLoading = false,
  video,
  videoPoster,
  theme = "default",
  backgroundTheme = "default",
  hasBorders = true,
  containImage = true,
  component = "SectionSplash",
  size = "fullScreen",
  justify = "center",
  layout = "default",
  children,
  style = "default",
}) => {

  const { stickerSize } = title;
  const completeTitle = replaceWordsWithSticker(makeTitleFromArray(title.lines), stickerSize ?? "", theme);
  const titleTag = title.tag ? title.tag : "h2";
  const imageDictatesHeight = size === "auto" && image;
  const contentDictatesHeight = size === "auto" && !image && theme !== "default";
  const titleSize = textGroupSizeMap[title.size || "default"];

  return (
    <Section component={component} className={`w-screen overflow-hidden relative ${sizeClassMap[size]}`} theme={hasBorders ? theme : backgroundTheme} size={hasBorders ? "xl" : "none"}>
      { (image && !containImage) && (
        <Image
          image={image}
          advancedImages={advancedImages}
          alt={image.alt || completeTitle}
          lazy={!disableImageLazyLoading}
          cover
        />
      ) }
      { video && (
        <Video video={video} poster={videoPoster ?? ""} cover />
      ) }
      { ((image && !containImage) || video) && (
        <div className="absolute w-full h-full bg-black bg-opacity-20 top-0 left-0"></div>
      ) }
      <Container
        className={`h-full relative z-10  ${(!hasBorders && theme) ? `${themeRootClassMap[theme]} ${borderlessSectionBackgroundClassMap[theme]}` : undefined}`}
        theme={!hasBorders ? theme : undefined}
        padding={imageDictatesHeight ? "none" : "default"}
      >
        { (image && containImage) && (
          <Image
            image={image}
            advancedImages={advancedImages}
            alt={image.alt || completeTitle}
            cover={!imageDictatesHeight}
            lazy={!disableImageLazyLoading}
            className={`${style === "quote" ? "before:bg-black before:bg-opacity-60 before:absolute before:top-0 before:left-0 before:h-full before:w-full relative" : ""}`}
          />
        ) }
        <Grid className={`${style === "quote" ? "w-full px-8" : ""}  ${imageDictatesHeight ? "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" : "h-full relative"}`} theme={theme}>
          <Column justify={justify} className="h-full">
            { contentDictatesHeight && (
              <Spacer size="xl" />
            ) }
            <Text tag={titleTag} size={titleSize[title.display || "title"]} align={splashLayoutMap[layout].titleAlign} display={title.display || "title"} color={style === "quote" ? "secondary" : "default"}>
              <span dangerouslySetInnerHTML={{ __html: completeTitle }} />
            </Text>
            <Grid>
              <Column spans={{ lg: 8 }} offset={splashLayoutMap[layout].bodyOffset}>
                { !!subtitle?.text && (
                  <>
                    <Spacer size="xl" />
                    <Text size={legacySizeCollectionMap.bodyLg} align={splashLayoutMap[layout].bodyCopyAlign} display="subtitle" color="secondary" whitespace={{ lg: "preWrap" }}>{ subtitle.text }</Text>
                  </>
                ) }
                { body && (
                  <>
                    <Spacer size="lg" />
                    <Text align={splashLayoutMap[layout].bodyCopyAlign} size={legacySizeCollectionMap.bodyLg} whitespace={{ lg: "preWrap" }} color={style === "quote" ? "secondary" : "default"}>{ body }</Text>
                  </>
                ) }
                { !!ctas?.length && (
                  <>
                    <Spacer size="xl" />
                    <Grid className={layout === "secondary" ? "hidden lg:grid" : undefined}>
                      <Column justify={splashLayoutMap[layout].ctaJustify} direction="row" className="flex-wrap">
                        { ctas.map((cta) => (
                          <Button key={cta.id} to={cta.to} color={cta.color} design={cta.design} data-testid={cta.testId} className="mx-2 mb-4 last:mb-0">
                            { cta.name }
                          </Button>
                        )) }

                      </Column>
                    </Grid>
                  </>
                ) }

                { children && (
                  <>
                    <Spacer size="md" />
                    { children }
                    <Spacer size="md" />
                  </>
                ) }
              </Column>
            </Grid>
            { (!!ctas?.length && layout === "secondary") && (
              <>
                <Grid className="h-full lg:hidden">
                  <Column justify={splashLayoutMap[layout].ctaJustify} direction="row" align="end" className="mb-8">
                    { ctas.map((cta) => (
                      <Button key={cta.id} to={cta.to} color={cta.color} design={cta.design} data-testid={cta.testId} className="mx-2">
                        { cta.name }
                      </Button>
                    )) }
                  </Column>
                </Grid>
                <Spacer size="3xl" />
              </>
            ) }
            { contentDictatesHeight && (
              <Spacer size="xl" />
            ) }
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionSplash;
